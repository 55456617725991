:where(.css-dev-only-do-not-override-1ae8k9u).ant-form-horizontal .ant-form-item-label{
  flex: 0 0 25% !important;
  max-width: 25% !important;
  text-align: right;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-form-horizontal .ant-form-item-control{
  flex: 0 0 75% !important;
  max-width: 75% !important;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-modal .ant-modal-content,
:where(.css-dev-only-do-not-override-1ae8k9u).ant-card .ant-card-body{
  padding: 20px 16px;
}
